<template>
  <er-card class="card-pond-performance er-card--secondary" v-loading="loading" element-loading-background="white">
    <!-- Loading Spinner -->
    <div class="page_loading" v-if="loading"></div>

    <!-- Card Header -->
    <layout-toolbar v-show="!loading" slot="header" class="card__header">
      <img class="card-icon" src="@/assets/dashboard/pondsperformance.svg" v-show="!loading" />
      <span class="card-title">{{ $t("ponds_performance") }}</span>
      <div class="filler"></div>
      <el-button-group class="filter-icon">
        <el-button size="mini" type="text" @click="handleOpenFilterModal">
          <span class="material-icons-outlined" :title="$t('Comn_Filters')">
            filter_alt
          </span>
        </el-button>
      </el-button-group>
    </layout-toolbar>
    <el-row type="flex" class="card-pond-abw" justify="space-around" align="center" v-show="!loading">
      <span v-if="getTableData.length === 0">
        {{ $t("Comn_no_data_connection") }}
      </span>
      <el-table v-else :data="getTableData" size="mini" class="blue-row-table" row-class-name="blue-row-table__row"
        header-row-class-name="blue-row-table__header-row">
        <el-table-column prop="docLabel" label="row header" align="center" :min-width="80">
          <template slot="header">
            <div class="row-column-indicator">
              <div>&nbsp;</div>
              <div style="color: #233a73">{{ $t("ABW_abw") }}</div>
              <div style="color: #233a73">{{ $t("DOC_doc") }}</div>
              <div>&nbsp;</div>
            </div>
          </template>
        </el-table-column>

        <!-- Dynamic ABW Columns -->
        <el-table-column v-for="abwCol in abwColumns" :key="abwCol.column_key" :prop="abwCol.propKey" align="center"
          :min-width="80">
          <template slot="header">
            <div class="title-section">
              <p class="symbol-holder">{{ abwCol.label_symbol }}</p>
              <div class="title-container">
                <div class="title">{{ abwCol.label }}</div>
                <div class="sub-title">
                  {{ abwCol.units }}
                </div>
              </div>
            </div>

            <!-- <div style="text-align: center; font-weight: bold;">
              {{ abwCol.displayLabel }}
            </div> -->
          </template>
          <template slot-scope="scope">
            <span :style="{ cursor: getCursorType(scope.row[abwCol.propKey]) }"
              @click="handlePonds(scope.row[abwCol.propKey].ponds)">
              {{
                ftm__formatEmpty(scope.row[abwCol.propKey].ponds.length, true)
              }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <ABWDOCFilters :visible="filterClicked" @close="handleCloseFilterModal">
    </ABWDOCFilters>
  </er-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import resizeContainerMixin from "@/mixins/resizeContainerMixin";
import userPreferenceMixin from "@/mixins/userPreferenceMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import redirectsMixin from "@/mixins/redirectsMixin";
import ABWDOCFilters from "./ABWDOCFilters.vue";
import { RANGE_TYPES } from "./utils";

export default {
  name: "PondsPerformanceCard",
  components: {
    ABWDOCFilters
  },
  mixins: [
    filtersMixin,
    redirectsMixin,
    errorHandlerMixin,
    resizeContainerMixin,
    userPreferenceMixin,
    datesHandlerMixin
  ],
  props: ["isLoading"],
  data() {
    return {
      numberOfAbws: 5,
      numberOfDocs: 5,
      filterClicked: false,
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserId: "getUserId",
    }),
    ...mapGetters("dashboard", {
      getPondPerformance: "getPondPerformance",
      getDashboardFilters: "getDashboardFilters",
    }),
    pondPerformanceFilters() {
      return this.getDashboardFilters.POND_PERFORMANCE;
    },
    loading() {
      return this.isLoading;
    },
    abwRangeTypeToTitleFunc: function () {
      const unitsLang = this.$tc(
        `units.${this.getPreferredUnits.abw_and_growth_units}`,
        1
      );
      return {
        [RANGE_TYPES.IN_BETWEEN]: (start, end) => ({
          label_symbol: "≤",
          column_key: `${start}≤${end}`,
          label: `${end}`,
          units: unitsLang
        }),
        [RANGE_TYPES.GREATER_THAN]: (start, end) => ({
          label_symbol: ">",
          column_key: `>${start}`,
          label: `${start}`,
          units: unitsLang
        }),
        [RANGE_TYPES.LESS_THAN_OR_EQUALS]: (start, end) => ({
          label_symbol: "≤",
          column_key: `≤${start}-${end}`,
          label: `${end}`,
          units: unitsLang
        })
      };
    },
    abwColumns() {
      const abwRanges = this.pondPerformanceFilters.abw_ranges;
      return abwRanges.map((pref, index) => ({
        ...this.abwRangeTypeToTitleFunc[pref.range_type](pref.start, pref.end),
        propKey: `abw_${index}`
      }));
    },
    docRangeTypeToTitleFunc: function () {
      return {
        [RANGE_TYPES.IN_BETWEEN]: (start, end) => `> ${start}-${end}`,
        [RANGE_TYPES.GREATER_THAN]: (start, end) => `> ${start}`,
        [RANGE_TYPES.LESS_THAN_OR_EQUALS]: (start, end) => `≤ ${end}`
      };
    },
    docRows() {
      const docRanges = this.pondPerformanceFilters.doc_ranges;
      return docRanges.map((range, index) => ({
        displayLabel: this.docRangeTypeToTitleFunc[range.range_type](
          range.start,
          range.end
        ),
        propKey: `doc_${index}`
      }));
    },
    getTableData() {
      const docRowsCount = this.docRows.length;
      const abwColumnsCount = this.abwColumns.length;
      const tableData = [];
      if (this.getPondPerformance.length === 0) {
        return tableData;
      }
      for (let docRow = 0; docRow < docRowsCount; docRow++) {
        if (this.getPondPerformance[docRow]?.length === 0) continue;
        const rowObj = { docLabel: this.docRows[docRow].displayLabel };
        for (let abwCol = 0; abwCol < abwColumnsCount; abwCol++) {
          if (this.getPondPerformance[docRow]?.abw_range?.length === 0) continue;
          const abwObj = this.getPondPerformance[docRow]?.abw_range[abwCol] || {
            ponds: []
          };
          rowObj[`abw_${abwCol}`] = {
            ...abwObj,
            start: abwObj.range1,
            end: abwObj.range2
          };
        }
        tableData.push(rowObj);
      }
      return tableData;
    }
  },
  methods: {
    ...mapActions("dashboard", {
      pondPerformanceHyperlinkData: "pondPerformanceHyperlinkData",
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator",
    }),
    handleCloseFilterModal() {
      this.filterClicked = false;
    },
    getCursorType(cellValue) {
      return cellValue.ponds.length > 0 ? "pointer" : "not-allowed";
    },
    handleOpenFilterModal() {
      this.filterClicked = true;
    },
    async handlePonds(ponds) {
      if (ponds && ponds.length) {
        await this.pondPerformanceHyperlinkData({ value: ponds });
        this.mixPanelEventGenerator({
          eventName: "Dashboard - Pond Hyper Link Event"
        });
        this.rdm__navigateToPathWithLocationId({
          path: "/user/dashboard",
          query: { tab: "pondInfoTab" }
        });
      }
    }
  }
};
</script>

<style lang="scss">
@mixin component-font-size {
  @include responsiveProperty(font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3 );
}

.card-pond-performance {
  display: flex;
  flex-direction: column;

  .page_loading {
    @include responsiveProperty(height, 40vh, 40vh, 40vh);
    width: 100%;
    background-color: white;
    text-align: center;
  }

  &.er-card.er-card--secondary .el-card__body {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    justify-content: center;
    padding: 5px 20px 10px 8px;
  }

  .filter-icon .el-button--text {
    color: unset;
  }

  .card-icon {
    width: 24px; // adjust as needed
    height: 24px;
    margin-right: 8px;
  }

  .blue-row-table {
    $table-row-border-color: #b3b3b3;
    $table-header-cell-font-color: #233a73;

    &.el-table {
      width: 100%;
    }

    &.el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: unset;
    }

    .blue-row-table__header-row {
      background-color: white;

      th {
        padding: 1px 0px;

        .cell {
          font-weight: 500;
        }
      }

      th:first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.08);
      }

      th:not(:first-child) {
        color: $table-header-cell-font-color;
        background: #f8f8f8;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);

        .cell {
          display: inline-flex;
          flex-direction: row;
          justify-content: center;
          line-height: 1;
          @include component-font-size;

          .title-section {
            display: flex;
            flex-direction: row;
            padding: 1px;
          }

          .title-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            padding-left: 5px;
          }

          .symbol-holder {
            margin-right: 0px;
          }

          div.title {
            text-align: initial;
          }

          div.sub-title {
            text-align: left;
            @include component-font-size;
            color: #508ff4;
          }
        }
      }

      th:last-child {
        border-right: 1px solid rgba(0, 0, 0, 0.15);
      }
    }

    .blue-row-table__row {
      td .cell {
        font-weight: 600;
        @include component-font-size;
        @include responsiveProperty(line-height, 1.65, 2.55, 3);
      }

      td:first-child {
        border-bottom: unset;
        background-color: #f8f8f8 !important;
        border-right: 1px solid rgba(0, 0, 0, 0.15);

        .cell {
          font-weight: 500;
          color: #233a73;
        }
      }

      &:not(:nth-child(2n)) {
        background-color: rgba(237, 244, 254, 0.8);
      }
    }

    .el-table__body,
    .el-table__header {
      padding-left: 5px;
    }

    .el-table__header-wrapper,
    .el-table__body-wrapper {
      margin-right: 5px;
      margin-left: 5px;
    }

    &.el-table::before {
      margin-left: 12px;
    }
  }

  .el-table th > .cell {
    padding: 0px;
    line-height: 120%;
  }

  .row-column-indicator {
    display: grid;
    height: 50%;
    @include component-font-size;
    grid-template-rows: repeat(2, 50% [col-start]);
    grid-template-columns: repeat(2, 50% [col-start]);
    align-items: start;

    & > div:nth-child(1),
    & > div:nth-child(2) {
      border-bottom: 1.5px solid #777777;
    }

    & > div:nth-child(1),
    & > div:nth-child(3) {
      border-right: 1.5px solid #777777;
    }

    & > div:nth-child(3):after,
    & > div:nth-child(2):after {
      content: "";
      position: absolute;
      display: inline-block;
      width: 8px;
      height: 8px;
      border-top: 1.5px solid #777777;
      border-left: 1.5px solid #777777;
      border-radius: 2px;
    }

    & > div:nth-child(3):after {
      transform: rotate(225deg);
      @include responsiveProperty(right, 45%, 46.25%, 48%);
      bottom: 0px;
      @include responsiveProperty(margin, 1.5px 1px, 1.5px 0px, 1.5px 0px);
    }

    & > div:nth-child(2):after {
      @include responsiveProperty(top, 50%, 50%, 50%);
      @include responsiveProperty(right, 1px, 0.5px, 0.5px);
      @include responsiveProperty(margin-top, -4.5px, -4.5px, -4.5px);
      transform: rotate(135deg);
    }
  }
}
</style>
