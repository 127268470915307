<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: GrowthRangeItem.vue
Description:this file is incorporated with AverageWeeklyGrowth where porgress bar is added.child component of Averge Weekly growth Component
-->
<template>
  <div class="growth-range-item-container">
    <div class="container__columns__alignment">
      <slot name="range__value">
        <div class="range__value">
          {{ rangeMathbetween[range.rangeType] }} {{ range.range1
          }}{{ rangeSeparationsymbols[range.rangeType] }}{{ range.range2 }}
        </div>
      </slot>
      <div
        v-bind:style="{ color: range.color }"
        class="total__ponds"
        @click="handleAverageWeeklyGrowth(range)"
      >
        {{ range.total_ponds }}
      </div>
    </div>
    <el-progress
      :percentage="+range.percent"
      :color="range.color"
      :show-text="false"
      :key="range.name"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import redirectsMixin from "@/mixins/redirectsMixin";
export default {
  name: "GrowthRangeItem",
  mixins: [redirectsMixin],
  props: {
    range: Object
  },
  data: function () {
    return {
      loading: false,
      percentage: 20,
      customColor: "#409eff"
    };
  },
  computed: {
    ...mapGetters("dashboard", {
      getDashboardPonds: "getDashboardPonds"
    }),

    rangeMathbetween: function () {
      var symbolobject = {
        LESS_THAN_OR_EQUALS: "\u2264",
        GREATER_THAN: ">",
        IN_BETWEEN: ">"
      };
      return symbolobject;
    },
    rangeSeparationsymbols: function () {
      var symbols = {
        IN_BETWEEN: "-"
      };
      return symbols;
    },
    getActivePondIds() {
      return this.getDashboardPonds.filter(x => x.status === 'ACTIVE').map(x => x._id)
    }
  },
  methods: {
    ...mapActions("dashboard", {
      averageWeeklyGrowthHyperLinkData: "averageWeeklyGrowthHyperLinkData"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    async handleAverageWeeklyGrowth(input) {
      if (input.name === "Ponds with out ABW") {
        const setOfActivePonds = new Set(this.getActivePondIds)
        const setOfPondsLessThanInputValue = new Set(input.pondIdsLessThanInputRange)
        const setOfPondsWithABWAndGreaterThanInputValue = new Set(input.pondIdsWithABW)
        const pondIdsWithoutABW = setOfActivePonds.difference(setOfPondsLessThanInputValue.union(setOfPondsWithABWAndGreaterThanInputValue));
        if (pondIdsWithoutABW.size > 0) {
          await this.averageWeeklyGrowthHyperLinkData({
            ponds: [...pondIdsWithoutABW]
          });
          this.mixPanelEventGenerator({ eventName: "Dashboard - Pond Hyper Link Event" });
          this.rdm__navigateToPathWithLocationId({
            path: "/user/dashboard",
            query: {
              tab: "pondInfoTab"
            }
          });
        }
        return
      }
      if (input.ponds.length > 0) {
        await this.averageWeeklyGrowthHyperLinkData({
          ponds: input.ponds
        });
        this.mixPanelEventGenerator({ eventName: "Dashboard - Pond Hyper Link Event" });
        this.rdm__navigateToPathWithLocationId({
          path: "/user/dashboard",
          query: {
            tab: "pondInfoTab"
          }
        });
      }
    }
  }
};
</script>

<style lang="scss">
@mixin component-font-size {
  @include responsiveProperty(
    font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3
  );
}
.growth-range-item-container {
  .total__ponds {
    @include component-font-size;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    cursor: pointer;
  }
  .range__value:after {
    content: "gms";
    font-weight: 500;
    @include component-font-size;
    color: #508ff4;
    padding-left: 5px;
  }
  .range__value {
    @include component-font-size;
    display: flex;
    align-items: flex-end;
    color: #140f26;
  }
  .container__columns__alignment {
    display: flex;
    justify-content: space-between;

    @include responsiveProperty(margin-top, 3.8px, 6.8px, 12px);
    @include responsiveProperty(padding-top, 6.5px, 15px, 20px);

    //8 //0

    // @include reposnsiveProperty(padding-bottom, 10px, 11px, 13px);

    // @include responsiveProperty(line-height, 1.95, 2.2, 2.4);

    // flex-direction: row;
    // margin-bottom: 5px;
  }
}
</style>
