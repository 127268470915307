<template>
  <!-- Filter Modal -->
  <FiltersModal
    :title="$t('Pond_performance_modal')"
    class="pond-performance-filter-modal"
    :showFiltersModal="visible"
    :loading="filterLoading"
    @save="applyRanges"
    @close="closeFilterModal"
    @open="handleOpenDialog"
    @reset="resetRanges"
  >
    <!-- <template slot="header">
      <el-popover
        placement="bottom"
        title="Title"
        width="200"
        trigger="hover"
        content="this is content, this is content, this is content">

        <div class="el-dialog__title" slot="reference">
          {{ $t('Pond_performance_modal') }}
          <i class="el-icon-info"></i>
        </div>
      </el-popover>
    </template> -->
    <template slot="rightPanel">
      <!-- Top Fields: Number of ABWs and DOCs -->
      <el-row :gutter="20" class="filter-header">
        <el-col :span="12">
          <div class="input-group">
            <label> {{ $t('abw-ranges') }} </label>
            <el-input-number
              :key="updateCountsOnNull.abw"
              :value="numberOfAbws"
              :controls="false"
              :min="4"
              :max="7"
              :step="1"
              :placeholder="`4 ${$t('Comn_to')} 7`"
              size="mini"
              @input="onAbwCountChange"
            />
          </div>
        </el-col>
        <el-col :span="12">
          <div class="input-group">
            <label>{{ $t("doc-ranges") }}</label>
            <el-input-number
              :key="updateCountsOnNull.doc"
              :value="numberOfDocs"
              :controls="false"
              :min="4"
              :max="7"
              :step="1"
              :placeholder="`4 ${$t('Comn_to')} 7`"
              size="mini"
              @input="onDocCountChange"
            />
          </div>
        </el-col>
      </el-row>
      <!-- Side-by-side layout for ABW and DOC ranges -->
      <el-row :gutter="20" class="filter-body">
        <!-- ABW Column -->
        <el-col :span="12">
          <div class="range-section">
            <h4>{{ $t("ABW_abw") }}</h4>
            <div
              v-for="(range, index) in abwRanges"
              :key="index"
              class="range-row"
            >
              <template v-if="index === abwRanges.length - 1">
                <span class="logical-symbol greater-than-symbol">></span>
                <el-input-number
                  :value="range.start"
                  disabled
                  :placeholder="$t('Comn_from')"
                  :min="0"
                  :controls="false"
                  :precision="1"
                  :step="0.1"
                  size="mini"
                  class="range-input"
                />
              </template>
              <template v-else>
                <span
                  :class="`logical-symbol less-than-or-equal-symbol hide`"
                >
                 >
                </span>
                <el-input-number
                  :value="range.start"
                  disabled
                  :placeholder="$t('Comn_from')"
                  :min="0"
                  :precision="1"
                  :controls="false"
                  :step="0.1"
                  size="mini"
                  class="range-input"
                />
                <span class="range-dash">-</span>
                  <el-input-number
                    :key="range.update_end_on_null"
                    :value="range.end"
                    :placeholder="$t('Comn_to')"
                    :min="range.start"
                    :max="FIELD_LENGTHS.MAX_ABW"
                    size="mini"
                    :precision="1"
                    :controls="false"
                    :step="0.1"
                    :class="`range-input ${getErrorClassIfIndexInErrorIndices(index, 'abw')}`"
                    @input="(value) => handleChangeAbwEndValue(value, index)"
                  />
              </template>

              <div class="range-actions">
                <er-button
                  :showIcon="true"
                  size="mini"
                  btnType="add"
                  @click="() => addAbwRange(index)"
                  :disabled="abwRanges.length === 7"
                  class="add-btn"
                ></er-button>
                <er-button
                  btnType="delete"
                  :showIcon="true"
                  size="mini"
                  @click="() => removeAbwRange(index)"
                  :disabled="abwRanges.length <= 4"
                ></er-button>
              </div>
            </div>
          </div>
          <el-alert
            v-if="abwErrorMessage"
            :title="$t(abwErrorMessage)"
            type="error"
            :closable="false"
          >
          </el-alert>
        </el-col>
        <!-- DOC Column -->
        <el-col :span="12">
          <div class="range-section">
            <h4>{{ $t("DOC_doc") }}</h4>
            <div
              v-for="(range, index) in docRanges"
              :key="index"
              class="range-row"
            >
              <template v-if="index === docRanges.length - 1">
                <!-- Last DOC Range should only have "greater than" -->
                <span class="logical-symbol greater-than-symbol"> > </span>
                <el-input-number
                  :value="range.start"
                  disabled
                  :placeholder="$t('Comn_from')"
                  :min="0"
                  :controls="false"
                  :step="1"
                  size="mini"
                  class="range-input"
                />
              </template>
              <template v-else>
                <!-- Regular DOC Range -->
                <span
                  :class="`logical-symbol less-than-or-equal-symbol hide
                  }`"
                >
                  >
                </span>
                <el-input-number
                  :value="range.start"
                  disabled
                  :placeholder="$t('Comn_from')"
                  :min="0"
                  :controls="false"
                  :step="1"
                  size="mini"
                  class="range-input"
                />
                <span class="range-dash">-</span>
                <el-input-number
                  :key="range.update_end_on_null"
                  :value="range.end"
                  :placeholder="$t('Comn_to')"
                  size="mini"
                  :step="1"
                  :controls="false"
                  :precision="0"
                  :min="range.start"
                  :max="FIELD_LENGTHS.MAX_DOC"
                  :class="`range-input ${getErrorClassIfIndexInErrorIndices(index, 'doc')}`"
                  @input="(value) => handleChangeDOCEndValue(value, index)"
                />
              </template>

              <div class="range-actions">
                <er-button
                  :showIcon="true"
                  size="mini"
                  btnType="add"
                  @click="() => addDocRange(index)"
                  :disabled="docRanges.length === 7"
                  class="add-btn"
                ></er-button>
                <er-button
                  btnType="delete"
                  :showIcon="true"
                  size="mini"
                  @click="() => removeDocRange(index)"
                  :disabled="docRanges.length <= 4"
                ></er-button>
              </div>
            </div>
          </div>
          <el-alert
            v-if="docErrorMessage"
            :title="$t(docErrorMessage)"
            :closable="false"
            type="error"
          >
          </el-alert>
        </el-col>
      </el-row>
    </template>
  </FiltersModal>
</template>

<script>
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import filtersMixin from "@/mixins/filtersMixin";
import { mapGetters, mapActions } from "vuex";
import FiltersModal from "../FiltersModal.vue";
import FIELD_LENGTHS from "@/constants/fieldLengths";
import { getRangeValue, RANGE_TYPES } from "./utils";
import { POND_PERFORMANCE_FILTERS as DEFAULT_FILTERS } from "../../utils/defaultDashboardFilters";

export default {
  props: ["visible"],
  mixins: [filtersMixin, errorHandlerMixin],
  components: {
    FiltersModal,
  },
  data: function () {
    return {
      numberOfAbws: 5,
      numberOfDocs: 5,
      abwRanges: [],
      docRanges: [],
      filterLoading: false,
      abwErrorRangesIndex: new Set(),
      docErrorRangesIndex: new Set(),
      FIELD_LENGTHS,
      abwErrorMessage: "",
      docErrorMessage: "",
      updateCountsOnNull: {
        abw: 0,
        doc: 0
      },
      DEFAULT_FILTERS,
    };
  },
  computed: {
    ...mapGetters("dashboard", {
      getPondPerformance: "getPondPerformance",
      getDashboardFilters: "getDashboardFilters",
    }),
    ...mapGetters("user", {
      getUserId: "getUserId"
    }),
    pondPerformanceFilters() {
      return this.getDashboardFilters.POND_PERFORMANCE;
    },
  },
  methods: {
    ...mapActions("dashboard", {
      updateDashboardFilters: "updateDashboardFilters",
    }),
    handleOpenDialog() {
      this.abwRanges = this.pondPerformanceFilters.abw_ranges.map(x => getRangeValue(x.start, x.end, x.end === null, x.start === 0))
      this.docRanges = this.pondPerformanceFilters.doc_ranges.map(x => getRangeValue(x.start, x.end, x.end === null, x.start === 0))
      this.numberOfAbws = this.abwRanges.length
      this.numberOfDocs = this.docRanges.length
      this.abwErrorRangesIndex = new Set()
      this.docErrorRangesIndex = new Set()
      this.abwErrorMessage = ""
      this.docErrorMessage = ""
    },
    resetABWErrors() {
      this.abwErrorRangesIndex = new Set()
      this.abwErrorMessage = ""
    },
    resetDOCErrors() {
      this.docErrorRangesIndex = new Set()
      this.docErrorMessage = ""
    },
    closeFilterModal() {
      this.$emit("close");
    },
    onAbwCountChange(nVal) {
      this.resetABWErrors()
      if (nVal === undefined) {
        this.updateCountsOnNull.abw += 1
        return;
      }
      if (nVal < 4) this.numberOfAbws = 4;
      else if (nVal > 7) this.numberOfAbws = 7;
      const currCount = this.abwRanges.length;
      const newCount = nVal;
      const diff = newCount - currCount;
      for (let rangeNum = 0; rangeNum < Math.abs(diff); rangeNum++) {
        if (diff > 0) {
          this.addAbwRange(currCount + rangeNum - 1);
        } else {
          this.removeAbwRange(currCount - rangeNum - 1);
        }
      }
    },
    onDocCountChange(nVal, oVal) {
      this.resetDOCErrors()
      if (nVal === undefined) {
        this.updateCountsOnNull.doc += 1
        return
      }
      if (nVal < 4) this.numberOfDocs = 4;
      else if (nVal > 7) this.numberOfDocs = 7;
      const currCount = this.docRanges.length;
      const newCount = nVal;
      const diff = newCount - currCount;
      for (let rangeNum = 0; rangeNum < Math.abs(diff); rangeNum++) {
        if (diff > 0) {
          this.addDocRange(currCount + rangeNum - 1);
        } else {
          this.removeDocRange(currCount - rangeNum - 1);
        }
      }
    },
    handleChangeAbwEndValue(value, index) {
      this.resetABWErrors()
      if (value === undefined) {
        this.abwRanges[index].update_end_on_null += 1
        return
      }
      this.abwRanges[index].end = Math.min(Math.max(value, this.abwRanges[index].start), FIELD_LENGTHS.MAX_ABW)
      this.abwRanges[index + 1].start = this.abwRanges[index].end
    },
    handleChangeDOCEndValue(value, index) {
      this.resetDOCErrors()
      if (value === undefined) {
        this.docRanges[index].update_end_on_null += 1
        return
      }
      this.docRanges[index].end = Math.min(Math.max(Math.round(value), this.docRanges[index].start), FIELD_LENGTHS.MAX_DOC)
      this.docRanges[index + 1].start = this.docRanges[index].end
    },
    addAbwRange(index) {
      this.resetABWErrors();
      if (this.abwRanges.length === 7) {
        return;
      }
      const isEndValueUnbounded = this.abwRanges.length - 1 === index;
      if (isEndValueUnbounded) {
        this.abwRanges[index].end = Math.min(
          this.abwRanges[index].start + 5,
          FIELD_LENGTHS.MAX_ABW
        );
        this.abwRanges[index].is_end_value_infinite = false;
        this.abwRanges[index].range_type = RANGE_TYPES.IN_BETWEEN;
      }
      const newStart = this.abwRanges[index].end;
      const newEnd = isEndValueUnbounded ? null : Math.min(newStart + 5, FIELD_LENGTHS.MAX_ABW);
      this.abwRanges.splice(
        index + 1,
        0,
        getRangeValue(newStart, newEnd, isEndValueUnbounded, false)
      );
      this.numberOfAbws = this.abwRanges.length;
    },
    removeAbwRange(index) {
      this.resetABWErrors();
      if (this.abwRanges.length === 4) {
        return;
      }
      const prevLen = this.abwRanges.length;
      this.abwRanges.splice(index, 1);
      if (index === 0) {
        this.abwRanges[0].start = 0;
        this.abwRanges[0].range_type = RANGE_TYPES.LESS_THAN_OR_EQUALS;
      } else if (index + 1 === prevLen) {
        this.abwRanges[index - 1].start = this.abwRanges[index - 2].end;
      } else {
        this.abwRanges[index].start = this.abwRanges[index - 1].end;
      }
      this.numberOfAbws = this.abwRanges.length;
    },
    addDocRange(index) {
      this.resetDOCErrors();
      if (this.docRanges.length === 7) {
        return;
      }
      const isEndValueUnbounded = this.docRanges.length - 1 === index;
      if (isEndValueUnbounded) {
        this.docRanges[index].end = Math.min(
          this.docRanges[index].start + 5,
          FIELD_LENGTHS.MAX_DOC
        );
        this.docRanges[index].is_end_value_infinite = false
        this.docRanges[index].range_type = RANGE_TYPES.IN_BETWEEN;
      }
      const newStart = this.docRanges[index].end;
      const newEnd = isEndValueUnbounded ? null : newStart + 5;
      this.docRanges.splice(
        index + 1,
        0,
        getRangeValue(newStart, newEnd, isEndValueUnbounded, false)
      );
      this.numberOfDocs = this.docRanges.length;
    },
    removeDocRange(index) {
      this.resetDOCErrors();
      if (this.docRanges.length === 4) {
        return;
      }
      const prevLen = this.docRanges.length;
      this.docRanges.splice(index, 1);
      if (index === 0) {
        this.docRanges[0].start = 0;
        this.docRanges[0].range_type = RANGE_TYPES.LESS_THAN_OR_EQUALS;
      } else if (index + 1 === prevLen) {
        this.docRanges[index - 1].start = this.docRanges[index - 2].end;
      } else {
        this.docRanges[index].start = this.docRanges[index - 1].end;
      }
      this.numberOfDocs = this.docRanges.length;
    },
    getErrorClassIfIndexInErrorIndices(index, type) {
      if (type === "abw") {
        return this.abwErrorRangesIndex.has(index) ? "is-error" : ""
      }
      return this.docErrorRangesIndex.has(index) ? "is-error" : ""
    },
    validateRanges(listOfRanges) {
      const errorIndices = new Set()
      for (let rangei = 0; rangei < listOfRanges.length; rangei++) {
        for (let rangej = 0; rangej < listOfRanges.length; rangej++) {
          if (rangei < rangej) {
            const [riStart, riEnd] = listOfRanges[rangei]
            const [rjStart, rjEnd] = listOfRanges[rangej]
            if ((riStart < riEnd) && (rjStart < rjEnd) && (riEnd <= rjStart)) {
              continue
            }
            errorIndices.add(rangej)
          }
        }
      }
      return errorIndices
    },
    async applyRanges() {
      try {
        const abwIndices = this.validateRanges(this.abwRanges.map( x => [x.start, x.end || (FIELD_LENGTHS.MAX_ABW + 1)]))
        const docIndices = this.validateRanges(this.docRanges.map( x => [x.start, x.end || (FIELD_LENGTHS.MAX_DOC + 1)]))
        if (abwIndices.size > 0 || docIndices.size > 0) {
          throw {
            type: "FAIL_TO_SAVE",
            abwIndices: abwIndices,
            docIndices: docIndices
          }
        }
        this.filterLoading = true;

        const formatRangeValues = (ranges) => {
          return ranges.map((range) => {
            const obj = {
              start: range.start,
              end: range.end,
              range_type: range.range_type
            };
            return obj;
          });
        };

        const filterNames = ["abw_ranges", "doc_ranges"];
        const filterValues = [this.abwRanges, this.docRanges];

        const payload = {
          preferences: filterNames.map((filterName, index) => ({
            user_id: this.getUserId,
            widget_name: "POND_PERFORMANCE",
            filter_name: filterName,
            filter_type: "ARRAY",
            is_default: false,
            values: [formatRangeValues(filterValues[index])],
          })),
        };

        await this.updateDashboardFilters({
          widgetName: "POND_PERFORMANCE",
          params: payload,
        });

        this.filterClicked = false;

        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Filters_success_msg"),
          duration: 5000,
          type: "success",
        });
        this.$emit("close");
      } catch (err) {
        if (err.type === "FAIL_TO_SAVE") {
          this.abwErrorRangesIndex = err.abwIndices
          if (this.abwErrorRangesIndex.size > 0) {
            this.abwErrorMessage = "ranges-must-be-in-ascending-order-without-overlaps"
          }
          this.docErrorRangesIndex = err.docIndices
          if (this.docErrorRangesIndex.size > 0) {
            this.docErrorMessage = "ranges-must-be-in-ascending-order-without-overlaps"
          }
        } else {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.filterLoading = false;
      }
    },
    async resetRanges() {
      try {
        this.filterLoading = true;
        const formatRangeValues = (ranges) => {
          return ranges.map((range) => {
            const obj = {
              start: range.start,
              end: range.end,
              range_type: range.range_type
            };
            return obj;
          });
        };

        const filterNames = ["abw_ranges", "doc_ranges"];
        const filterValues = [
          DEFAULT_FILTERS.abw_ranges,
          DEFAULT_FILTERS.doc_ranges
        ];

        const payload = {
          preferences: filterNames.map((filterName, index) => ({
            user_id: this.getUserId,
            widget_name: "POND_PERFORMANCE",
            filter_name: filterName,
            filter_type: "ARRAY",
            is_default: false,
            values: [formatRangeValues(filterValues[index])]
          }))
        };

        await this.updateDashboardFilters({
          widgetName: "POND_PERFORMANCE",
          params: payload
        });

        this.filterClicked = false;

        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Filters_success_msg"),
          duration: 5000,
          type: "success"
        });
        this.$emit("close");
      } catch (err) {
        this.ehm__errorMessages(err, true);
      } finally {
        this.filterLoading = false;
      }
    }
  }
};
</script>

<style lang="scss">
.pond-performance-filter-modal {
  .filter-header {
    margin-bottom: 20px;
    margin-top: 15px;
  }

  .input-group {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      font-weight: 600;
      margin-right: 5px;
    }
  }

  .range-section {
    margin-bottom: 20px;

    h4 {
      margin-bottom: 10px;
    }

    .logical-symbol {
      margin-right: 5px;

      &.hide {
        opacity: 0;
      }
    }

    .range-actions {
      margin-left: 10px;
      padding: 5px 5px;

      .er-button.el-button--mini:not(.is-circle) {
        padding: 3px 5px;
      }
    }

    .range-row {
      display: flex;
      align-items: center;

      & + .range-row {
        margin-top: 8px;
      }

      .range-input {
        width: 65px;
        &.is-error .el-input__inner{
          border-color: #F56C6C;
        }
        .el-input.is-disabled .el-input__inner {
          color: #54445c;
        }
      }

      .range-dash {
        margin: 0 8px;
      }

      .remove-btn {
        margin-left: 8px;
        color: #ff4d4f;
      }
    }

    .add-range-btn {
      margin-top: 10px;
    }
  }

  .dialog-footer {
    text-align: right;
  }
}
</style>
