<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: AverageWeeklyGrowth.vue
Description:this file gives the data averge weekly growth of the ponds for last 4 weeks
-->
<template>
  <er-card class="weight-growth-container" v-loading="loading" element-loading-background="white">
    <div class="page_loading" v-if="loading"></div>
    <layout-toolbar v-show="!loading" slot="header" type="flex" justify="start" class="weight__growth__header">
      <span class="card-container">
        <img class="card__icon" src="@/assets/dashboard/weightGrowthIcon.svg" />
        <span :title="$t('average_weekly_growth')">{{ $t("Comn_awg") }}</span>
      </span>
      <div class="filler"></div>
      <el-row class="weight-growth-stat">
        <el-row :class="[
          'weight__growth__container',currentWeek === index ? 'is-selected' : '',
        ]" v-for="(eachWeek, index) in getAvgWeeklyGrowth" :key="index" type="flex"
          @click.native="currentWeek = index">
          <el-col tag="span" class="weight__growth__value"
            :data-units="$tc(getPreferredUnits.abw_and_growth_units, 2)">
            {{ ftm__formatEmpty(ftm__digitPrecision(eachWeek, 1)) }}
          </el-col>
        </el-row>
      </el-row>
      <el-button-group class="awg-filter-icon">
        <el-button size="mini" type="text" @click="handleFilterClick">
          <span
          class="material-icons-outlined"
          :title="$t('Comn_Filters')"
        >
          filter_alt
        </span>
        </el-button>
      </el-button-group>
    </layout-toolbar>
    <template v-if="!loading">
      <template v-if="getWeightGrowth.length > 0">
        <div class="header">
          <span>{{ $t("Comn_range") }}</span>
          <span>{{ $t("Comn_ponds") }}</span>
        </div>
        <hr />
        <GrowthRangeitem :range="getPondsWithABWRangeItem[currentWeek]">
          <template slot="range__value">
            <p class="normal-text">
              {{ `${$t("without")} ${$t("ABW_abw")}` }}
            </p>
          </template>
        </GrowthRangeitem>
        <GrowthRangeitem v-for="item in getWeightGrowth" :key="item.name" :range="item" />
      </template>
      <el-row type="flex" align="center" justify="center" class="empty-container-wrapper" v-else>
        {{ $t("Comn_no_data_connection") }}
      </el-row>
    </template>
    <FiltersModal
      :title="$t('Comn_awg')"
      :showFiltersModal="filterClicked"
      :loading="filterLoading"
      @save="handleSaveFilterData"
      @close="handleCloseDialog"
      @reset="() => handleReset(...Object.values(DEFAULT_FILTERS))"
      >
      <template slot="rightPanel">
        <el-form :model="formModel" :rules="validationRules" ref="filters-form" :validate-on-rule-change="false">
          <el-form-item prop="abw" :label="$t('AWG_ABW_field_label')">
              <el-input-number
                v-model="formModel.abw"
                :controls="false"
                class="input_abw"
                size="mini"
                :precision="1"
                :step="0.1"
              ></el-input-number>
            </el-form-item>
        </el-form>
      </template>
    </FiltersModal>
  </er-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import GrowthRangeitem from "../../dashboard/components/GrowthRangeItem";
import filtersMixin from "@/mixins/filtersMixin";
import FiltersModal from "./FiltersModal.vue";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { AVERAGE_WEEKLY_GROWTH_FILTERS as DEFAULT_FILTERS } from "../utils/defaultDashboardFilters";
export default {
  name: "WeightGrowthCard",
  mixins: [filtersMixin, errorHandlerMixin],
  components: {
    GrowthRangeitem,
    FiltersModal
  },
  props: ["isLoading"],
  data: function () {
    return {
      weightGrowthRange: [],
      query: {
        location_id: null
      },
      queryNweeks: {
        location_id: null,
        number_of_weeks: 4
      },
      rangeObject: {
        range1: 0,
        rangeType: "LESS_THAN_OR_EQUALS",
        range2: "",
        name: "Ponds with out ABW",
        total_ponds: 0,
        color: "#909399",
        percent: 0
      },
      currentWeek: 3,
      pondIds: [],
      filterClicked: false,
      formModel: {
        abw: 0.1,
      },
      filterLoading: false,
      DEFAULT_FILTERS
    };
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getPreferredUnits: "getPreferredUnits",
      getUserId: "getUserId"
    }),
    ...mapGetters("dashboard", {
      getStoreWeightGrowth: "getWeightGrowth",
      getStoreGrowthofNWeeks: "getweightGrowthOfNWeeks",
      getDashboardFilters: "getDashboardFilters"
    }),
    weeklyGrowthFilters() {
      return this.getDashboardFilters.AVERAGE_WEEKLY_GROWTH
    },
    getAvgWeeklyGrowth() {
      return (this.getStoreGrowthofNWeeks || []).map((x) =>
        x.ponds_with_abw > 0 ? x.total_growth / x.ponds_with_abw : null
      );
    },
    getPondsWithABWRangeItem() {
      const nWeekGrowth = this.getStoreGrowthofNWeeks;
      if (!nWeekGrowth || !nWeekGrowth.length) {
        return Array(4).fill(this.rangeObject);
      }
      return nWeekGrowth.map((x) => ({
        range1: "No ABW",
        range2: "",
        name: "Ponds with out ABW",
        rangeType: "LESS_THAN_OR_EQUALS",
        total_ponds: x.ponds_without_abw,
        color: "#909399",
        percent: x.total_ponds
          ? (x.ponds_without_abw * 100) / x.total_ponds
          : 0,
        pondIdsWithABW: this.getStoreGrowthofNWeeks[this.currentWeek].ponds,
        pondIdsWithoutABW: this.getStoreGrowthofNWeeks[this.currentWeek].pond_ids_without_abw,
        pondIdsLessThanInputRange: this.getStoreGrowthofNWeeks[this.currentWeek].ponds_less_than_given_input
      }));
    },
    getNoOfWeeks() {
      return this.getAvgWeeklyGrowth.length;
    },
    getWeightGrowth() {
      if (!this.getStoreGrowthofNWeeks) {
        return [];
      }
      const colors = ["#F24654", "#FE9B3D", "#FFCC2F", "#32DB28", "#35A22E"];
      const weekObj = this.getStoreGrowthofNWeeks[this.currentWeek];
      if (!weekObj) {
        return [];
      }
      const keys = ["<=0", ">0-1", ">1-1.5", ">1.5-2", ">2"];
      const weekAvgGrowth = weekObj.growth_ranges;
      const total = weekObj.total_ponds;
      return keys.map((key, index) => {
        const item = weekAvgGrowth[key];
        const percent = (item.total_ponds * 100) / total;
        const color = colors[index];
        item.color = color;
        item.percent = percent;
        item.pondIdsWithABW = this.getStoreGrowthofNWeeks[this.currentWeek].ponds;
        item.pondIdsWithoutABW = this.getStoreGrowthofNWeeks[this.currentWeek].pond_ids_without_abw;
        item.pondIdsLessThanInputRange = this.getStoreGrowthofNWeeks[this.currentWeek].ponds_less_than_given_input
        return item;
      });
    },
    loading() {
      return this.isLoading;
    },
    validationRules: function() {
      return {
        abw: [
          { required: true, message: this.$t("Comn_abw_invalid"), trigger: 'blur' },
          { type: 'number', min: 0.1, max: 200, message: this.$t("Comn_abw_invalid"), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    ...mapActions("dashboard", {
      updateDashboardFilters: "updateDashboardFilters"
    }),
    handleFilterClick() {
      this.formModel.abw = this.weeklyGrowthFilters.abw_input;
      this.filterClicked = true;
    },
    handleCloseDialog() {
      this.$refs['filters-form'].clearValidate('abw');
      this.filterClicked = false;
    },
    async handleSaveFilterData() {
      this.$refs['filters-form'].validate(async (valid) => {
        if (valid) {
          try {
            this.filterLoading = true;
            const payload = {
              preferences: [
                {
                  user_id: this.getUserId,
                  widget_name: "AVERAGE_WEEKLY_GROWTH",
                  filter_name: "abw_input",
                  filter_type: "FLOAT",
                  is_default: false,
                  values: [this.formModel.abw]
                },
              ]
            }
            await this.updateDashboardFilters({ widgetName: "AVERAGE_WEEKLY_GROWTH", params: payload });
            this.filterClicked = false;
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("Filters_success_msg"),
              duration: 5000,
              type: "success"
            });
          } catch (err) {
            this.ehm__errorMessages(err, true);
          } finally {
            this.filterLoading = false;
          }
        }
      })
    },
    async handleReset(value) {
      try {
            this.filterLoading = true;
            const payload = {
              preferences: [
                {
                  user_id: this.getUserId,
                  widget_name: "AVERAGE_WEEKLY_GROWTH",
                  filter_name: "abw_input",
                  filter_type: "FLOAT",
                  is_default: false,
                  values: [value]
                },
              ]
            }
            await this.updateDashboardFilters({ widgetName: "AVERAGE_WEEKLY_GROWTH", params: payload });
            this.filterClicked = false;
            this.filterLoading = false;
            this.$notify({
              title: this.$t("Usrs_success_msg"),
              message: this.$t("Filters_success_msg"),
              duration: 5000,
              type: "success"
            });
          } catch (err) {
            this.ehm__errorMessages(err, true);
          } finally {
            this.filterLoading = false;
          }
    }
  }
};
</script>

<style lang="scss">
@mixin component-font-size {
  @include responsiveProperty(font-size,
    $app_font_size_1,
    $app_font_size_2,
    $app_font_size_3 );
}

.weight-growth-container {
  display: flex;
  flex-direction: column;

  .filters-modal{
    @include responsiveProperty(--dialog-width, 45%, 35%, 25%);
    .el-form-item__content {
      display: inline-block;
      width: 200px;
    }
  }

  .el-card__body{
    display: flex;
    flex-direction: column;
    flex: 1 1;
  }
  .empty-container-wrapper{
    flex-direction: column;
    flex: 1 1;
    text-align: center;
  }

  .awg-filter-icon {
    display: flex;
    justify-content: end;
    .el-button--text {
      color: unset;
    }
  }

  .page_loading {
    @include responsiveProperty(height, 40vh, 40vh, 40vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }

  .weight__growth__header {
    display: flex;
    align-items: center;
    justify-content: center;

    @include responsiveProperty(padding-bottom, 1px, 0px, 0px);

    .card-container {
      // @include responsiveProperty(padding-bottom, 0px, 1px, 0px);
      display: flex;

      // @include responsiveProperty(font-size,
      //   $app_font_size_small,
      //   $app_font_size_1,
      //   $app_font_size_3 );

      .card__icon {
        display: flex;
        margin-right: 10px;
      }

      .card__header {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .pond-card-count__header__total {
    text-transform: capitalize;
  }

  .wholeiconalign {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .weight-growth-stat {
    display: flex;
    justify-content: flex-end;
    font-weight: 200;
    border-radius: 5px;
    .weight__growth__container {
      cursor: pointer;
      @include component-font-size;
      color: #0078ff;
      background-color: #d6eaff;
      margin: 0px 3px;
      padding: 4px;
      padding: -1px;
      border-radius: 5px;
      text-transform: lowercase;

      &.is-selected {
        color: #ffffff;
        background-color: #233a73;

        .weight__growth__value {
          &:after {
            color: #5df953;
          }
        }
      }

      @include responsiveProperty(font-size,
        $app_font_size_small,
        $app_font_size_1,
        $app_font_size_2 );

    }

    .weight__growth__value {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      flex-direction: column;
      letter-spacing: initial;
      @include responsiveProperty(min-width, 20px, 35px, 40px);
      @include responsiveProperty(max-width, 20px, 35px, 40px);

      &:after {
        content: attr(data-units);
        @include responsiveProperty(font-size,
          $app_font_size_v_small,
          $app_font_size_small,
          $app_font_size_1 );
        margin-left: 5px;
        text-transform: lowercase;
        color: #0f9807;
        line-height: 1;
        margin-left: auto;
      }
    }

    .weight__growth__units {
      color: #5df953;
      display: block;
      text-align: end;
      letter-spacing: initial;
      @include responsiveProperty(font-size,
        $app_font_size_v_small,
        $app_font_size_v_medium,
        $app_font_size_small );
    }
  }

  .header {
    display: flex;
    justify-content: space-between;

    @include responsiveProperty(font-size,
      $app_font_size_small,
      $app_font_size_1,
      $app_font_size_2 );
    letter-spacing: 1px;
    line-height: 2;
    color: #0a2463;
    font-weight: 600;
  }

  hr {
    background: #6c7b8a;
    mix-blend-mode: normal;
    opacity: 0.08;
  }
}
</style>
