<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: dashboardTab.vue
Description:this file contains the info about all the cards in the Dashboard
-->
<template>
  <el-row class="dashboard-tab" slot="layout-pondlogs-scrollable-main">
    <el-row type="flex" :gutter="10" class="overall-dashboard-row">
      <el-col :span="6">
        <PondsCountCard :isLoading="loading['PondsCountCard']" />
      </el-col>
      <el-col :span="9">
        <FeedConsumption :isLoading="loading['FeedConsumption']" />
      </el-col>
      <el-col :span="9">
        <ABWBiomassCard :isLoading="loading['ABWBiomassCard']" />
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="10" class="overall-dashboard-row">
      <el-col :span="5" class="do-temp-stats">
        <el-row>
          <TemperatureCard :isLoading="loading['TemperatureCard']" />
        </el-row>
        <el-row>
          <DissolvedOxygenCard :isLoading="loading['DissolvedOxygenCard']" />
        </el-row>
      </el-col>
      <el-col :span="7">
        <AverageWeeklyGrowth :isLoading="loading['AverageWeeklyGrowth']" />
      </el-col>
      <el-col :span="12">
        <PondPerformanceCard :isLoading="loading['PondPerformanceCard']" />
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import PondsCountCard from "./components/PondsCountCard";
import FeedConsumption from "./components/FeedConsumptionCard";
import ABWBiomassCard from "./components/ABWBiomassCard";
import PondPerformanceCard from "./components/PondPerformanceCard";
import AverageWeeklyGrowth from "./components/AverageWeeklyGrowth";
import TemperatureCard from "./components/TemperatureCard";
import DissolvedOxygenCard from "./components/DissolvedOxygenCard";
import filtersMixin from "@/mixins/filtersMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { getNameForRange } from "./components/PondPerformanceCard/utils";

export default {
  name: "PondDashboard",
  mixins: [filtersMixin, errorHandlerMixin],
  components: {
    PondsCountCard,
    FeedConsumption,
    ABWBiomassCard,
    PondPerformanceCard,
    TemperatureCard,
    DissolvedOxygenCard,
    AverageWeeklyGrowth
  },
  data: function () {
    return {
      loading: {
        PondsCountCard: true,
        FeedConsumption: true,
        ABWBiomassCard: true,
        PondPerformanceCard: true,
        AverageWeeklyGrowth: true,
        TemperatureCard: true,
        DissolvedOxygenCard: true
      },
      query: {
        location_id: null
      },
      widgetNameFtToBk: {
      FeedConsumption: 'FEED_CONSUMPTION',
      AverageWeeklyGrowth: 'AVERAGE_WEEKLY_GROWTH',
      PondPerformanceCard: 'POND_PERFORMANCE'
    }
    };
  },
  watch: {
    getIsWeeklyGrowthFiltersUpdated(newVal) {
      if (newVal) {
        this.initComponent(["AverageWeeklyGrowth"])
      }
    },
    getIsPondPerformenceFiltersUpdated(newVal) {
      if (newVal) {
        this.initComponent(["PondPerformanceCard"]);
      }
    },
    getIsFeedConsumptionFiltersUpdated(newVal) {
      if (newVal) {
        this.initComponent(["FeedConsumption"]);
      }
    },
  },
  computed: {
    ...mapGetters("user", {
      getCurrUserLocation: "getCurrUserLocation",
      getUserId: "getUserId"
    }),
    ...mapGetters("dashboard", {
      getDashboardFilters: "getDashboardFilters",
      getIsFeedConsumptionFiltersUpdated: "getIsFeedConsumptionFiltersUpdated",
      getIsWeeklyGrowthFiltersUpdated: "getIsWeeklyGrowthFiltersUpdated",
      getIsPondPerformenceFiltersUpdated: "getIsPondPerformenceFiltersUpdated"
    }),
    weeklyGrowthFilters() {
      return this.getDashboardFilters.AVERAGE_WEEKLY_GROWTH
    },
    feedConsumptionFilters() {
      return this.getDashboardFilters.FEED_CONSUMPTION
    },
    pondPerformanceCardFilters() {
      return this.getDashboardFilters.POND_PERFORMANCE
    },
    pondPerformanceCardFiltersForBk() {
      return {
        abw_ranges: this.pondPerformanceCardFilters.abw_ranges.map( x => ({ name: getNameForRange(x), range1: x.start, range2: x.end, range_type: x.range_type })),
        doc_ranges: this.pondPerformanceCardFilters.doc_ranges.map( x => ({ name: getNameForRange(x), range1: x.start, range2: x.end, range_type: x.range_type })),
      }
    },
    feedConsumptionFiltersForBk() {
      return {
        type: this.getDashboardFilters.FEED_CONSUMPTION.feeding_frequency,
        mode: this.getDashboardFilters.FEED_CONSUMPTION.feeding_mode
      }
    },
  },
  async mounted() {
    this.INIT_STORE_STATE()
    await this.initComponent(["PondsCountCard",
    "FeedConsumption",
    "ABWBiomassCard",
    "PondPerformanceCard",
    "AverageWeeklyGrowth",
    "TemperatureCard",
    "DissolvedOxygenCard"
    ]);
  },
  methods: {
    ...mapActions("dashboard", {
      fetchPondPerformance: "fetchPondPerformance",
      fetchAverageWeightGrowthLastNWeeks: "fetchAverageWeightGrowthLastNWeeks",
      fetchDOSummary: "fetchDOSummary",
      fetchTempSummary: "fetchTempSummary",
      fetchAbwAndBiomass: "fetchAbwAndBiomass",
      fetchFeedConsumptionData: "fetchFeedConsumptionData",
      fetchAllPonds: "fetchAllPonds",
      fetchDashboardFilters: "fetchDashboardFilters",
    }),
    ...mapMutations("dashboard", ["INIT_STORE_STATE"]),
    async initComponent(widgetNames) {
      const widgetsWithFilters = widgetNames.map(ftWidgetName => this.widgetNameFtToBk[ftWidgetName]).filter(widgetName => widgetName !== undefined)
      widgetNames.forEach((key) => {
        this.loading[key] = true;
      })
      try {
        await this.fetchDashboardFilters({
          widgetNames: widgetsWithFilters,
          params: {
            widgetName: widgetsWithFilters,
            userId: this.getUserId
          }
        })
      } finally {
        widgetNames.forEach((key) => {
          this.loading[key] = false;
        })
        this.query.location_id =
          this.getCurrUserLocation && this.getCurrUserLocation._id;
        const dashboardStoreActions = {
          PondsCountCard: this.fetchAllPonds,
          FeedConsumption: this.fetchFeedConsumptionData,
          ABWBiomassCard: this.fetchAbwAndBiomass,
          PondPerformanceCard: this.fetchPondPerformance,
          AverageWeeklyGrowth: this.fetchAverageWeightGrowthLastNWeeks,
          TemperatureCard: this.fetchTempSummary,
          DissolvedOxygenCard: this.fetchDOSummary
        };
        const dashboardActionsQueryParamsObj = {
          PondsCountCard: {
            ...this.query,
            status: ["ACTIVE", "INACTIVE"],
            include: ["status"],
            get_all: true,
            not_include_test_ponds: true
          },
          FeedConsumption: {
            ...this.query,
            ...this.feedConsumptionFiltersForBk
          },
          ABWBiomassCard: { ...this.query },
          PondPerformanceCard: { ...this.query, ...this.pondPerformanceCardFiltersForBk },
          AverageWeeklyGrowth: { ...this.query, ...this.weeklyGrowthFilters },
          TemperatureCard: { ...this.query },
          DissolvedOxygenCard: { ...this.query }
        };
        try {
          await Promise.all(
            Object.keys(dashboardStoreActions).filter( x => widgetNames.includes(x)).map((key) => {
              this.loading[key] = true;
              const promise = (
                dashboardStoreActions[key](dashboardActionsQueryParamsObj[key]) ||
                Promise.resolve()
              ).finally(() => {
                this.loading[key] = false;
              });
              return promise;
            })
          );
        } catch (err) {
          this.ehm__errorMessages(err, true);
        }
      }
    }
    // }
  }
};
</script>

<style lang="scss">
@import "../../../styles/mixins.scss";
.dashboard-tab {
  .overall-dashboard-row {
    &:first-child {
      margin-bottom: 10px;
      .el-card__header {
        line-height: 1.4;
      }
    }
    .el-card {
      // @include responsiveProperty(height, 44vh, 45vh, 46vh);
      height: 100%;
    }
  }
  .do-temp-stats {
    display: flex;
    flex-direction: column;
    > .el-row {
      height: calc(50% - 5px);
      margin-bottom: 5px;
      margin-top: 5px;
    }
    > .el-row:first-child {
      margin-top: 0px;
    }
    > .el-row:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>
