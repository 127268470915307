<template>
  <er-dialog
    :width="width"
    :visible.sync="localShowFiltersModal"
    :append-to-body="false"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    :title="title"
    @close="handleCloseDialog"
    @open="handleOpenDialog"
    class="filters-modal"
  >
    <template #header>
      <slot name="header"></slot>
    </template>
    <!-- <el-row type="flex" justify="space-between"> -->
      <!-- <div class="left-panel">
        <slot name="leftPanel"></slot>
      </div> -->
        <!-- <div class="vertical-line"></div> -->
      <div class="right-panel">
        <!-- <el-scrollbar
          class="right-panel-scroll"
          viewClass="right-panel-scroll-view"
          wrapClass="right-panel-scroll-wrap"
        > -->
          <slot name="rightPanel"></slot>
        <!-- </el-scrollbar> -->
      </div>
    <!-- </el-row> -->
    <template slot="footer">
      <er-button
        btnType="save"
        :showLabel="true"
        :showIcon="true"
        :loading="loading"
        @click="saveFilters"
      ></er-button>
      <er-button
        btnType="cancel"
        :showLabel="true"
        @click="handleCloseDialog"
      ></er-button>
      <er-button
        btnType="cancel"
        type="text"
        class="reset-button"
        :showLabel="false"
        :showIcon="false"
        :title="$t('Reset')"
        @click="handleReset"
      >
      <i class="material-icons-outlined" >
        restore
      </i>
      <span class="button-label">{{ $t('Reset') }}</span>
      </er-button>
    </template>
  </er-dialog>
</template>
<script>
export default {
  props: {
    title: {
      default: "",
      type: String
    },
    showFiltersModal: {
      default: false,
      type: Boolean
    },
    loading: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: "var(--dialog-width)"
    }
  },
  data: function() {
    return {
      localShowFiltersModal: this.showFiltersModal
    }
  },
  watch: {
    showFiltersModal(newValue) {
      this.localShowFiltersModal = newValue;
    }
  },

  methods: {
    handleOpenDialog() {
      this.$emit("open")
    },
    handleCloseDialog() {
      this.$emit("close");
    },
    saveFilters() {
      this.$emit("save");
      // this.localShowFiltersModal = false;
    },
    handleReset() {
      this.$emit("reset")
    }
  }
}
</script>
<style lang="scss">
.filters-modal {
  @include responsiveProperty(--dialog-width, 60%, 45%, 40%);
  .el-dialog__body{
    padding: 0px 20px;
  }
  .right-panel{
    width: 100%;
    overflow: hidden;
  }
  // .left-panel {
  //   width: 70%;
  //   overflow: hidden;
  // }
  .right-panel-scroll {
    position: relative;
    margin-right: -20px;
    @include responsiveProperty(height, 55vh, 59vh, 63vh);
  }
  .right-panel-scroll-view {
    height: 100%;
    padding-right: 10px;
  }
  .right-panel-scroll-wrap {
    height: 100%;
    overflow-x: auto;
  }
  .vertical-line {
    border-left: 1px solid #f0f0f0;
    min-height: 200px;
    max-height: 500px;
  }

  .el-alert__title{
    word-break: break-word;
  }
  .reset-button{
    padding: unset;
    & > span{
      display:flex;
      flex-direction: row;
      align-items: center
    }

    span.button-label {
      margin-left: 5px;
      color: #606266;
    }
  }
}

</style>
